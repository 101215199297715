import { Component } from "react";
import Heading from "components/01-atoms/heading/heading";
import Paragraph from "components/01-atoms/paragraph/paragraph";
import Icon from "components/01-atoms/icon/icon";
import Button from "components/01-atoms/button/button";

class Card extends Component {
  render() {
    return (
      <div
        className={` ${this.props.className ? this.props.className : ""} card`}
      >
        <Heading className="card-title" level="h3">
          {this.props.heading}
        </Heading>
        <Paragraph className="card-p">{this.props.text}</Paragraph>
        <Icon className="card-img" src={this.props.icon} />
        <Button isbutton="true" className="hero__button card-button">
          Más
        </Button>
      </div>
    );
  }
}

export default Card;
