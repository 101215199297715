import { Component, createRef } from "react";
import Lottie from "public/lotties/person.json";
import { Player } from "@lottiefiles/react-lottie-player";
import Heading from "components/01-atoms/heading/heading";
import Paragraph from "components/01-atoms/paragraph/paragraph";
import Button from "components/01-atoms/button/button";
import ImgHero from "public/hero.png";

class Hero extends Component {
  constructor(props) {
    super(props);
    this.person = createRef();
    this.lottie = Lottie;
  }

  render() {
    return (
      <section className="hero web-element">
        <div className="hero-content web-element-content">
          <div className="hero-content">
            <img alt="" src={ImgHero} className="hero__img" />
          </div>

          <div className="hero-img">
            <Heading className="hero-title" level="h1">
              Lo que siempre buscaste
            </Heading>
            <Paragraph className="hero-p">
              Encuentra el trabajo de tus sueños y comienza a ganar dinero con
              las ventajas del trabajo remoto. Balancea en tiempo como mas
              gustes
            </Paragraph>
            <Button className="hero__button"> 📞 </Button>
          </div>
        </div>
      </section>
    );
  }
}

export default Hero;
