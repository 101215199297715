import { Component } from "react";
import Logo from "components/01-atoms/logo/logo";
import Hamburger from "components/01-atoms/hamburger/hamburger";
import Button from "components/01-atoms/button/button";
import AppContext from "context/app-context";

class MobileNavigation extends Component {
  static contextType = AppContext;

  render() {
    return (
      <div className="second-nav section">
        <div className="second-nav-content section-content">
          <div className="second-nav-container">
            <Logo />
            <Hamburger />
          </div>
          <div
            className={`menu second-nav-menu ${
              this.context.mobileNavigation.isVisible ? "menu-visible" : ""
            }`}
          >
            <div className="menuurl links">
              <a className="links-element">Blog</a>
              <a
                className="links-element"
                href="https://www.youtube.com/dfloresdev"
              >
                YouTube Channel
              </a>
              <a className="links-element" href="/">
                LinkedIn
              </a>
              <a className="links-element" href="/">
                Tuiter
              </a>
            </div>
            <figure className="menu__separator"></figure>
            <div className="menu__actions">
              <Button className="actions__button">Notas</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileNavigation;
