import { Component } from "react";
import Heading from "components/01-atoms/heading/heading";

class Logo extends Component {
  render() {
    return <Heading level="h22">Trabaja Ya!</Heading>;
  }
}

export default Logo;
