import { Component } from "react";
import Heading from "components/01-atoms/heading/heading";
import Paragraph from "components/01-atoms/paragraph/paragraph";
import Card from "components/02-molecules/card/card";
import IconGoogle from "public/icons/icons8-logo-de-google.svg";
import IcontTwitter from "public/icons/icons8-twitter.svg";
import IconMac from "public/icons/icons8-mac-os.svg";

class Cards extends Component {
  render() {
    return (
      <section className="cards web-element">
        <div className="cards-titles web-element-content">
          <Heading className="cards__heading" level="h2">
            La empresa de tus sueños
          </Heading>
          <Paragraph className="cards-p">
            Encuentra trabajo en las mejores empresas de Tech para desempeñar y
            hacer tu carrera profesional. No pierdas esta oportunidad
          </Paragraph>
          <div className="cards-container">
            <Card
              className="cards-element"
              heading="Trabaja en Google"
              text="No te pierdas la oportunidad de tener una entrevista con esta gran empresa"
              icon={IconGoogle}
            />
            <Card
              className="cards-element"
              heading="Trabaja en Twitter"
              text="No te pierdas la oportunidad de tener una entrevista con esta gran empresa"
              icon={IcontTwitter}
            />
            <Card
              className="cards-element"
              heading="Trabaja en Apple"
              text="No te pierdas la oportunidad de tener una entrevista con esta gran empresa"
              icon={IconMac}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Cards;
