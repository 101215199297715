import { Component } from "react";
import Logo from "components/01-atoms/logo/logo";
import Link from "components/01-atoms/link/link";
import Button from "components/01-atoms/button/button";

class DesktopNavigation extends Component {
  render() {
    return (
      <div className="main-nav web-element">
        <div className="main-nav-content web-element-content">
          <Logo />
          <div className="main-nav-url links">
            <Link className="links_element">Blog</Link>
            <Link
              className="links_element"
              url="https://www.youtube.com/dfloresdev"
              external="true"
            >
              YouTube channel
            </Link>
            <Link
              className="links_element"
              url="https://www.linkedin.com/in/dfloresdev/"
            >
              LinkedIn
            </Link>
            <Link
              className="links_element"
              url="https://twitter.com/dfloresdev"
            >
              Tuiter
            </Link>
          </div>
          <div className="main-nav__actions">
            <Button className="actions__button" isbutton="true">
              Notas
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default DesktopNavigation;
