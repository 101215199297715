import { Component } from "react";

class Input extends Component {
  render() {
    return (
      <div
        className={` ${this.props.className ? this.props.className : ""} ${
          this.props.modifier ? `input--${this.props.modifier}` : ""
        } input`}
      >
        <input
          className="input-element"
          type="text"
          value={this.props.value}
          onChange={this.props.onChange}
          placeholder={` ${
            this.props.placeholder ? this.props.placeholder : ""
          } `}
        />
        {/* <p className="input-message paragraph paragraph--small">
          Error en la casilla
        </p> */}
      </div>
    );
  }
}

export default Input;
