import { Component } from "react";

class Button extends Component {
  render() {
    if (this.props.isbutton) {
      return (
        <button
          className={`${this.props.className} button ${
            this.props.modifier ? `button--${this.props.modifier}` : ""
          }`}
          href="/"
          onClick={this.props.onClick}
          data-value={this.props.dataValue ? this.props.dataValue : ""}
        >
          {this.props.children}
        </button>
      );
    } else {
      return (
        <a
          className={`${this.props.className} button ${
            this.props.modifier ? `button--${this.props.modifier}` : ""
          }`}
          href="/"
          onClick={this.props.onClick}
          data-value={this.props.dataValue ? this.props.dataValue : ""}
        >
          {this.props.children}
        </a>
      );
    }
  }
}

export default Button;
