import { Component, createRef } from "react";
import Button from "components/01-atoms/button/button";
import { Player } from "@lottiefiles/react-lottie-player";
import Input from "components/01-atoms/input/input";
import LottieA from "public/lotties/blob-a.json";
import LottieB from "public/lotties/blob-a.json";
import Output from "components/02-molecules/output/output";
import AppContext from "context/app-context";
import base64 from "base-64";

class Controller extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.blobA = createRef();
    this.blobB = createRef();
    this.lottieA = LottieA;
    this.lottieB = LottieB;
  }

  componentDidMount = () => {
    this.context.initControllerOutputItems();
  };

  onSubmit = (event) => {
    event.preventDefault();
    console.log(
      "%cEres genial, gracias por tomar mi curso <3",
      "color: #FFFFFF; font-size: 45px; background: #333333; text-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #FF2D95 0px 0px 20px, #FF2D95 0px 0px 30px, #FF2D95 0px 0px 40px, #FF2D95 0px 0px 50px, #FF2D95 0px 0px 75px;",
    );
  };

  minifyLink = (link) => {
    const baseUrl = "https://rel.ink/";
    const hash = base64.encode(link).substring(0, 5);
    const minifiedLink = `${baseUrl}${hash}`;
    return minifiedLink;
  };

  onChange = (event) => {
    this.context.updateControllerInput(event.target.value);
  };

  validateLink = (link, successCallback, errorCallback) => {
    const expression = /[a-zA-Z0-9()]?/gi;
    const regex = new RegExp(expression);
    link.match(regex) ? successCallback() : errorCallback();
  };

  render() {
    return (
      <section className="input-sec web-element">
        <div className="input-sec-content web-element-content">
          <form
            className="input-sec__form form"
            onSubmit={this.onSubmit}
            readOnly
          >
            <div className="input-sec__form-container">
              <Input
                className=""
                placeholder="Nombre"
                modifier={
                  this.context.controller.status === "error" ? "error" : ""
                }
              />
              <Input
                className=""
                placeholder="Apellido"
                aria-label="Apellido"
                modifier={
                  this.context.controller.status === "error" ? "error" : ""
                }
              />
            </div>
            <div className="input-sec__form-container">
              <Input className="" placeholder="Edad" />
              <Input className="" placeholder="Idioma" />

              <Button
                className="form__button"
                modifier="squarish"
                onClick={(event) => this.onSubmit(event)}
              >
                Enviar
              </Button>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default Controller;
