import { Component } from "react";
import Logo from "components/01-atoms/logo/logo";
import Link from "components/01-atoms/link/link";
import SocialMediaIcons from "components/01-atoms/social-media-icons/social-media-icons";
import Paragraph from "components/01-atoms/paragraph/paragraph";

class Footer extends Component {
  render() {
    return (
      <footer className="footer web-element">
        <div className="footer-content web-element-content">
          <Paragraph className="hero-p">
            Con todo el cariño del mundo 💚
          </Paragraph>
        </div>
      </footer>
    );
  }
}

export default Footer;
