import { Component, createRef } from "react";
import Heading from "components/01-atoms/heading/heading";
import Button from "components/01-atoms/button/button";
import { Player } from "@lottiefiles/react-lottie-player";
import LottieA from "public/lotties/blob-a.json";
import LottieB from "public/lotties/blob-a.json";
import LottieC from "public/lotties/blob-c.json";

class Cta extends Component {
  constructor(props) {
    super(props);
    this.blobA = createRef();
    this.blobB = createRef();
    this.blobC = createRef();
    this.lottieA = LottieA;
    this.lottieB = LottieB;
    this.lottieC = LottieC;
  }

  render() {
    return (
      <section className="end web-element">
        <div className="end-content web-element-content">
          <Heading className="end__heading" level="h2">
            Gracias por tomar este increíble curso
          </Heading>
          <Button className="end__button">No olvides seguirme!</Button>
        </div>
      </section>
    );
  }
}

export default Cta;
